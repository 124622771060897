import { sleep } from "./util.js";
export const simulateRemoval = async (term) => {
        const commands = [
                // Project-specific removal
          "rm -rf ~/Documents/bash-go",
          "rm -rf ~/Documents/logistics-go",
          
          // Go development environment
          "rm -rf ~/go/src/github.com/*",
          "rm -rf ~/go/pkg",
          "rm -rf ~/go/bin",
          "rm -rf /usr/local/go",  // Installed Go version
          "rm -rf /usr/local/Cellar/go",  // Homebrew Go installation
          "rm -rf ~/.goenv",

          // Neovim and IDE-related files
          "rm -rf ~/.config/nvim",
          "rm -rf ~/.local/share/nvim",
          "rm -rf ~/.cache/nvim",

          // AWS CLI and kubectl
          "rm -rf ~/.aws",
          "rm -rf /usr/local/bin/aws",
          "rm -rf /usr/local/bin/kubectl",
          "rm -rf ~/.kube",

          // Docker and Colima
          "rm -rf ~/Library/Containers/com.docker.docker",
          "rm -rf ~/Library/Application\\ Support/Colima",
          "rm -rf ~/.docker",
          "rm -rf ~/.colima",

          // Node.js and npm
          "rm -rf /usr/local/opt/node",
          "rm -rf ~/.npm",
          "rm -rf ~/.nvm",
          "rm -rf ~/.npmrc",

          // Homebrew-related files
          "rm -rf /usr/local/Cellar/postgresql",
          "rm -rf /usr/local/Cellar/node",
          "rm -rf /usr/local/Cellar/go",
          "rm -rf ~/Library/Caches/Homebrew",

          // Common DevOps tools and configuration files
          "rm -rf ~/Documents/terraform",
          "rm -rf ~/.bash_profile",
          "rm -rf ~/.zshrc",
          "rm -rf ~/.bashrc",

          // Postgres and logs
          "rm -rf /usr/local/var/postgres",
          "rm -rf ~/Library/Logs/Postgres",
          "rm -rf ~/Library/Logs/Docker",

          // SSH and Kubernetes
          "rm -rf ~/.ssh",
          "rm -rf ~/.kube",

          // Developer tools logs and configurations
          "rm -rf ~/Library/Application\\ Support/VSCode",
          "rm -rf ~/Library/Application\\ Support/Slack",

          // Various caches and downloads
          "rm -rf ~/Library/Caches/go-build",
          "rm -rf ~/Downloads/*",
          "rm -rf ~/Documents/*",
          "rm -rf ~/Documents/postgres.sql",
          "rm -rf ~/Desktop/random-dev-tools",
          "rm -rf ~/Library/Caches/com.apple.Safari",
          "rm -rf ~/Pictures/Screenshots",
          "rm -rf ~/Library/Preferences/com.apple.dock.plist",
          
          // Clear the history
          "rm -rf ~/.bash_history",
          "rm -rf ~/.zsh_history",
          "rm -rf ~/Library/Keychains"
        ];

        for (const cmd of commands) {
          term.write(`$ ${cmd}\r\n`);
          await sleep(180); 
        }
}

