import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { GoodbyePage } from './pages/goodbye';


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route exact path="/" element={<GoodbyePage />} />
      <Route exact path="/bash" element={<GoodbyePage />} />
      </Routes>
    </BrowserRouter>
  );
}

