import { startScene } from './director';
import {sleep} from './util';

export const startTerminal = async (term, audio) => {
    term.open(document.getElementById('terminal-container'));

    term.onKey((event) => {
      event.domEvent.preventDefault(); // Disable any keypresses
    });

    term.focus();


    const currentDate = new Date();
    const formattedDate = currentDate.toDateString();
    const formattedTime = currentDate.toLocaleTimeString();

    await sleep(250)
    term.write(`Initializing environment... [OK]\r\n`);
    await sleep(300)
    term.write(`Checking system resources... [OK]\r\n`);
    await sleep(250)
    
term.write(`\x1b[31m[Warning]\x1b[0m Press any key to continue...\r\n`);

  // Wait for user input before proceeding
  const keyListener = term.onKey(async (key) => {
    // Once the key is pressed, remove the listener to avoid duplicates
     keyListener.dispose();
  

    audio.play();
    await sleep(500)
    term.write(`Connecting to bash-server... [OK]\r\n\r\n`);
    await sleep(1300)
    term.write(`Session joined successfully.\r\n\r\n`);

    term.write("Welcome to Bash 22.04.1 LTS (TFG/Labs 5.4.0-42-generic x86_64)\r\n\r\n");
    term.write(`System information as of ${formattedDate} ${formattedTime} \r\n\r\n`);
    term.write("  System load:  0.14             Processes:           250\r\n");
    term.write("  Memory usage: 7%               Disk usage:          5%\r\n");
    term.write("  Swap usage:   0%               Users logged in:     1\r\n");
    term.write(`\n\r\n`);

    await sleep(1000)
    term.write(`daniel@bash-server:~$ `);

   const writePrompt = () => {
      term.write(`daniel@bash-server:~$ `);
    };



    await sleep(2000)
    startScene(term,writePrompt,audio)
    });
}
