// util.js - just a bunch of hacks.
export const simulateTyping = async (term, text, baseDelay = 100, callback, callbackDelay = 500, undoDelay = 0) => {
  let index = 0;

  const getRandomDelay = () => baseDelay + Math.floor(Math.random() * 75);

  return new Promise(async (resolve) => {
    // Typing phase
    while (index < text.length) {
      term.write(text[index]);
      index++;
      await new Promise(r => setTimeout(r, getRandomDelay()));
    }

    if(undoDelay === 0){
    term.write('\r\n');
    }

    await new Promise(r => setTimeout(r, callbackDelay));

    if (undoDelay > 0) {
      for (let i = text.length - 1; i >= 0; i--) {
        if((text.length - i)  === 1){
        await new Promise(r => setTimeout(r, 5000));
        }

        term.write('\b \b'); 
        await new Promise(r => setTimeout(r, undoDelay));
      }
    }

    if (callback && callbackDelay > 0 && undoDelay === 0) {
      callback(); 
    }

    resolve();
  });
};

export const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));


export const incrementVolumeSmoothly = (audioRef) => {
      const volumeStep = 0.01; // How much to increment the volume by (every 50ms)
      const maxVolume = 1.0; // Target maximum volume
      const intervalTime = 1000; // Interval time in milliseconds
      
      const volumeInterval = setInterval(() => {
        if (audioRef.volume < maxVolume) {
          audioRef.volume = Math.min(audioRef.volume + volumeStep, maxVolume);
        } else {
          clearInterval(volumeInterval); // Stop incrementing when the max volume is reached
        }
      }, intervalTime); // Adjust this to control how smooth the volume increment is
    };
