// utils/simulateTyping.js
import { simulateTyping, sleep, incrementVolumeSmoothly } from './util';
import { simulateRemoval} from './rm';
import "../styles/glitch.css"

export const startScene =  async (term, callback ,audio) => {

    const stopAudioAtTime = () => {
      const targetStopTime = 110; 
      const currentTime = audio.currentTime; 
      const remainingTime = (targetStopTime - currentTime) * 1000; 
      const glitchAidio = new Audio('/assets/glitch.mp3');
      glitchAidio.loop = true;
      const termContainer = document.getElementById('terminal-container');

      if (remainingTime > 0) {
        setTimeout(() => {
          if (audio.currentTime) {
            glitchAidio.play();
            termContainer.classList.add('aggressive-glitch');
            
            audio.pause(); 
       
        setTimeout(() => {
        term.clear();
        glitchAidio.pause();
        termContainer.classList.remove('aggressive-glitch');
        simulateTyping(term, "shutdown", 100, callback, 0, 0)
        setTimeout(() => {
        term.dispose()
        }, 4000);
      },3000)
            console.log("Audio stopped at 01:54");
          }
        }, remainingTime);
      } else {
        glitchAidio.play(); 
        termContainer.classList.add('aggressive-glitch'); 
        audio.pause();
        
        setTimeout(() => {
        term.clear();
        glitchAidio.pause();
        termContainer.classList.remove('aggressive-glitch');
        simulateTyping(term, "shutdown", 100, callback, 0, 0)

        setTimeout(() => {
         term.dispose(); 
        }, 4000);
      },3000)
        
        console.log("Target time has already passed.");
      }
    };

  stopAudioAtTime(); 
  incrementVolumeSmoothly(audio)
  
  await sleep(100)
  await simulateTyping(term, 'cd /work/bash-go', 150, callback, 0, 0)
  term.write('daniel@bash-server:/work/bash-go$ ')
  await simulateTyping(term, 'git add . && git commit -m "last dance"', 100, callback, 0, 0)
  term.write('daniel@bash-server:/work/bash-go$ ')
  await simulateTyping(term, 'git push origin main -f', 150, callback, 0, 0) 
  await sleep(100)
  await simulateGitPushOutput(term)

  term.write('daniel@bash-server:/work/bash-go$ ')
  await simulateTyping(term, 'cd ~', 150, callback, 150, 0)

  await simulateTyping(term, 'sh wipe.sh', 170, callback, 700)
  await simulateRemoval(term)
  callback()

  await simulateTyping(term, 'sh thanks.sh', 200, callback, 0)
  const yellowText = "\x1b[33m";
  const resetText = "\x1b[0m";
  term.write(`${yellowText}Hey Bash Team, Supply Chain, and a Big Shoutout to Logistics,\r\n`);
  term.write("\r\n");
  term.write("To everyone at Bash, just wanted to take a quick moment to say thanks for an awesome journey together. It’s been a wild ride, and I’ve really enjoyed working with all of you.\r\n");
  term.write("\r\n");
  term.write("And of course, a special shoutout to my team, the Logistics team. You guys are truly next-level! It’s been an absolute pleasure working alongside you, and the experience has been nothing short of awesome!\r\n");
  term.write("\r\n");
  term.write("Thanks again, everyone - it’s been real. \r\n");
  term.write("\r\n");
  term.write("Cheers,\r\n");
  term.write("Dan\r\n");
  term.write(resetText);
  callback()
  
}


const simulateGitPushOutput = async (term) => {
  // Simulate typing the git push command
  await sleep(1000); // Slight pause before output

  // Simulate the output of the push command
  term.write("Enumerating objects: 42, done.\r\n");
  await sleep(500);
  term.write("Counting objects: 100% (42/42), done.\r\n");
  await sleep(500);
  term.write("Delta compression using up to 8 threads\r\n");
  await sleep(500);
  term.write("Compressing objects: 100% (21/21), done.\r\n");
  await sleep(500);
  term.write("Writing objects: 100% (42/42), 4.89 KiB | 4.89 MiB/s, done.\r\n");
  await sleep(500);
  term.write("Total 42 (delta 14), reused 0 (delta 0)\r\n");
  await sleep(500);
  term.write("remote: Resolving deltas: 100% (14/14), completed with 4 local objects.\r\n");
  await sleep(500);
  term.write("To https://github.com/bash/bash-go.git\r\n");
  term.write(" + 14d0ade...a7b8f3c main -> main (forced update)\r\n");
  await sleep(1000);
  
  // Final message
  term.write("Everything up-to-date.\r\n");
};

