import { useEffect, useState } from 'react';
import { Terminal } from 'xterm';
import 'xterm/css/xterm.css';
import { startTerminal } from '../components/terminal';
import { sleep } from '../components/util';

export const GoodbyePage = () => {
  const [showGlitch, setShowGlitch] = useState(false);
  
  useEffect(() => {
    const runGlitchEffect = async () => {
      await sleep(5000);
      setShowGlitch(false);
    };
    runGlitchEffect();
  }, []);

  useEffect(() => {
    if (!showGlitch) {
      const term = new Terminal({
        cursorBlink: true,
        cursorStyle: 'block',
        cursorWidth: 1,
        fontSize: 14,
        rows: 50,
        cols: 160,
      });
      const audio = new Audio('/assets/goodbye.mp3');  
      audio.volume = 0.01;
      audio.currentTime = 39;
      startTerminal(term, audio);
      return () => term.dispose();
    }
  }, [showGlitch]);

  return (
        <div
          id="terminal-container"
          style={{
            height: '100vh',
            width: '100vw',
            backgroundColor: '#000',
            color: '#fff',
            padding: '5px'
          }}
        >
    </div>
  );
};

